/**
 * @fileoverview gRPC-Web generated client stub for pb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var model_pb = require('./model_pb.js')
const proto = {};
proto.pb = require('./credential_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.CredentialServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.CredentialServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.PingCredentialMessage,
 *   !proto.pb.PingCredentialMessage>}
 */
const methodDescriptor_CredentialService_PingCredential = new grpc.web.MethodDescriptor(
  '/pb.CredentialService/PingCredential',
  grpc.web.MethodType.UNARY,
  proto.pb.PingCredentialMessage,
  proto.pb.PingCredentialMessage,
  /**
   * @param {!proto.pb.PingCredentialMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pb.PingCredentialMessage.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pb.PingCredentialMessage,
 *   !proto.pb.PingCredentialMessage>}
 */
const methodInfo_CredentialService_PingCredential = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pb.PingCredentialMessage,
  /**
   * @param {!proto.pb.PingCredentialMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pb.PingCredentialMessage.deserializeBinary
);


/**
 * @param {!proto.pb.PingCredentialMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pb.PingCredentialMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.PingCredentialMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.CredentialServiceClient.prototype.pingCredential =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.CredentialService/PingCredential',
      request,
      metadata || {},
      methodDescriptor_CredentialService_PingCredential,
      callback);
};


/**
 * @param {!proto.pb.PingCredentialMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.PingCredentialMessage>}
 *     Promise that resolves to the response
 */
proto.pb.CredentialServicePromiseClient.prototype.pingCredential =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.CredentialService/PingCredential',
      request,
      metadata || {},
      methodDescriptor_CredentialService_PingCredential);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.AddCredentialRequest,
 *   !proto.pb.AddCredentialResponse>}
 */
const methodDescriptor_CredentialService_AddCredential = new grpc.web.MethodDescriptor(
  '/pb.CredentialService/AddCredential',
  grpc.web.MethodType.UNARY,
  proto.pb.AddCredentialRequest,
  proto.pb.AddCredentialResponse,
  /**
   * @param {!proto.pb.AddCredentialRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pb.AddCredentialResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pb.AddCredentialRequest,
 *   !proto.pb.AddCredentialResponse>}
 */
const methodInfo_CredentialService_AddCredential = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pb.AddCredentialResponse,
  /**
   * @param {!proto.pb.AddCredentialRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pb.AddCredentialResponse.deserializeBinary
);


/**
 * @param {!proto.pb.AddCredentialRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pb.AddCredentialResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.AddCredentialResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.CredentialServiceClient.prototype.addCredential =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.CredentialService/AddCredential',
      request,
      metadata || {},
      methodDescriptor_CredentialService_AddCredential,
      callback);
};


/**
 * @param {!proto.pb.AddCredentialRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.AddCredentialResponse>}
 *     Promise that resolves to the response
 */
proto.pb.CredentialServicePromiseClient.prototype.addCredential =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.CredentialService/AddCredential',
      request,
      metadata || {},
      methodDescriptor_CredentialService_AddCredential);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.GetCredentialRequest,
 *   !proto.pb.GetCredentialResponse>}
 */
const methodDescriptor_CredentialService_GetCredential = new grpc.web.MethodDescriptor(
  '/pb.CredentialService/GetCredential',
  grpc.web.MethodType.UNARY,
  proto.pb.GetCredentialRequest,
  proto.pb.GetCredentialResponse,
  /**
   * @param {!proto.pb.GetCredentialRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pb.GetCredentialResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pb.GetCredentialRequest,
 *   !proto.pb.GetCredentialResponse>}
 */
const methodInfo_CredentialService_GetCredential = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pb.GetCredentialResponse,
  /**
   * @param {!proto.pb.GetCredentialRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pb.GetCredentialResponse.deserializeBinary
);


/**
 * @param {!proto.pb.GetCredentialRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pb.GetCredentialResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.GetCredentialResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.CredentialServiceClient.prototype.getCredential =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.CredentialService/GetCredential',
      request,
      metadata || {},
      methodDescriptor_CredentialService_GetCredential,
      callback);
};


/**
 * @param {!proto.pb.GetCredentialRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.GetCredentialResponse>}
 *     Promise that resolves to the response
 */
proto.pb.CredentialServicePromiseClient.prototype.getCredential =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.CredentialService/GetCredential',
      request,
      metadata || {},
      methodDescriptor_CredentialService_GetCredential);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.ChangePasswordRequest,
 *   !proto.pb.ChangePasswordResponse>}
 */
const methodDescriptor_CredentialService_ChangePassword = new grpc.web.MethodDescriptor(
  '/pb.CredentialService/ChangePassword',
  grpc.web.MethodType.UNARY,
  proto.pb.ChangePasswordRequest,
  proto.pb.ChangePasswordResponse,
  /**
   * @param {!proto.pb.ChangePasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pb.ChangePasswordResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pb.ChangePasswordRequest,
 *   !proto.pb.ChangePasswordResponse>}
 */
const methodInfo_CredentialService_ChangePassword = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pb.ChangePasswordResponse,
  /**
   * @param {!proto.pb.ChangePasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pb.ChangePasswordResponse.deserializeBinary
);


/**
 * @param {!proto.pb.ChangePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pb.ChangePasswordResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.ChangePasswordResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.CredentialServiceClient.prototype.changePassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.CredentialService/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_CredentialService_ChangePassword,
      callback);
};


/**
 * @param {!proto.pb.ChangePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.ChangePasswordResponse>}
 *     Promise that resolves to the response
 */
proto.pb.CredentialServicePromiseClient.prototype.changePassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.CredentialService/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_CredentialService_ChangePassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.UpdateCredentialRequest,
 *   !proto.pb.UpdateCredentialResponse>}
 */
const methodDescriptor_CredentialService_UpdateCredential = new grpc.web.MethodDescriptor(
  '/pb.CredentialService/UpdateCredential',
  grpc.web.MethodType.UNARY,
  proto.pb.UpdateCredentialRequest,
  proto.pb.UpdateCredentialResponse,
  /**
   * @param {!proto.pb.UpdateCredentialRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pb.UpdateCredentialResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pb.UpdateCredentialRequest,
 *   !proto.pb.UpdateCredentialResponse>}
 */
const methodInfo_CredentialService_UpdateCredential = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pb.UpdateCredentialResponse,
  /**
   * @param {!proto.pb.UpdateCredentialRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pb.UpdateCredentialResponse.deserializeBinary
);


/**
 * @param {!proto.pb.UpdateCredentialRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pb.UpdateCredentialResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.UpdateCredentialResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.CredentialServiceClient.prototype.updateCredential =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.CredentialService/UpdateCredential',
      request,
      metadata || {},
      methodDescriptor_CredentialService_UpdateCredential,
      callback);
};


/**
 * @param {!proto.pb.UpdateCredentialRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.UpdateCredentialResponse>}
 *     Promise that resolves to the response
 */
proto.pb.CredentialServicePromiseClient.prototype.updateCredential =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.CredentialService/UpdateCredential',
      request,
      metadata || {},
      methodDescriptor_CredentialService_UpdateCredential);
};


module.exports = proto.pb;

