import Crypto from "crypto";

export default {
  ALGORITHM: 'aes-256-cbc',
  BLOCK_SIZE: 16,
  decrypt: function (encrypted, key) {
    const contents = Buffer.from(encrypted, 'hex')
    const iv = contents.slice(0, this.BLOCK_SIZE)
    const textBytes = contents.slice(this.BLOCK_SIZE)
    const decipher = Crypto.createDecipheriv(this.ALGORITHM, key, iv)
    let decrypted = decipher.update(textBytes, 'hex', 'utf8')
    decrypted += decipher.final('utf8')
    return decrypted
  },
  encrypt: function (original, key) {
    const iv = Crypto.randomBytes(this.BLOCK_SIZE)
    const cipher = Crypto.createCipheriv(this.ALGORITHM, key, iv)
    let cipherText
    try {
      cipherText = cipher.update(original, 'utf8', 'hex')
      cipherText += cipher.final('hex')
      cipherText = iv.toString('hex') + cipherText
    } catch (e) {
      cipherText = null
    }
    return cipherText
  }
}